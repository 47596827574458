<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2022-03-29 17:15:48
 * @LastEditors  : BigBigger
-->
<template>
<div class="app-page-2">
  <div class="app-content">
    <div class="course-detail">
      <!-- 面包蟹 -->
      <div class="breadcrumb-box">
        <Breadcrumb :list="breadcrumbList" />
      </div>
      <div class="detail">
        <div class="detail-card">
          <CourseGroupDetailCard v-if="detail" :detail="detail" @confirm="toSingup" />
        </div>
        <div class="detail-flex">
          <div class="class-intro">
            <Tabs :detail="detail" :actived="activedTab" :list="tabList" @confirm="toSingup" @onChange="tabsOnChange"></Tabs>
            <CourseIntroduct :detail="detail" v-if="detail && activedTab === 0" />
            <CourseList :detail="detail" v-if="detail && activedTab === 1" />
            <TeacherIntroduct :detail="detail" v-if="detail && activedTab === 2" />
            <MatchingCourse :detail="detail" v-if="detail && activedTab === 4" />
          </div>
          <!-- <div class="class-right">
            <IntCard style="margin-bottom:32px;" v-if="detail">
              <img class="icon" slot="title-icon" src="@/assets/images/icon/icon-2.png">
              <div class="int-title" slot="title-name">购课咨询</div>
              <div class="int-teacher" slot="content">
                <div class="intt-info">
                  <p>咨询老师：{{detail.consultingTeacher}}</p>
                  <p>微信电话：{{detail.consultingPhone}}</p>
                </div>
                <img :src="detail.consultingQrCode" alt="">
              </div>
            </IntCard>
            <IntCard style="margin-bottom:32px;" v-if="detail && detail.protocolId">
              <img class="icon" slot="title-icon" src="@/assets/images/icon/icon-10.png">
              <div class="int-title" slot="title-name">课程协议</div>
              <div class="int-teacher" slot="content">
                <div class="int-btn" @click="showProtocol">课程协议模板</div>
              </div>
            </IntCard> -->
            <!-- <IntCard>
              <img class="icon" slot="title-icon" src="@/assets/images/icon/icon-1.png">
              <div class="int-title" slot="title-name">购课注意事项</div>
              <div class="int-question" slot="content">
                <el-tooltip placement="left" effect="light">
                  <div class="int-q-item">
                    <p class="key">01</p>
                    <p class="text">fhdsahfhsafhsiafhdsahfhsafhsiafhiashfiohafhdsahfhsafhsiafhiashfiohafhdsahfhsafhsiafhiashfiohafhiashfioha?</p>
                  </div>
                  <p style="max-width:247px;" slot="content">
                    fdsfsfsfdsffdsfsafsafdsfsaff
                  </p>
                </el-tooltip>
              </div>
            </IntCard> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
    <el-dialog title="课程协议模板" :visible.sync="dialogVisible">
      <el-image v-for="(s, index) in dialogSrc" :key="index" :src="s" alt="" style="width: 100%;" />
      <ElButton @click="dialogVisible = false" slot="footer">关闭</ElButton>
    </el-dialog>
  </div>
</div>
  
</template>

<script>
import { getCourseGroupDetail, checkBeforeCreate, getLessonGroupTips, getProtocol } from "@/api/api"
import Breadcrumb from '@/components/public/Breadcrumb'
import CourseGroupDetailCard from "@/components/courseGroup/CourseGroupDetailCard"
import IntCard from '@/components/courseGroup/IntCard'
import Tabs from '@/components/courseGroup/Tabs'
import CourseIntroduct from '@/components/courseGroup/CourseIntroduct'
import CourseList from '@/components/courseGroup/CourseList'
import TeacherIntroduct from '@/components/courseGroup/TeacherIntroduct'
import MatchingCourse from '@/components/courseGroup/MatchingCourse'
import { checkOrderStatus } from '@/views/common/check';

export default {
  name: "Detail",
  // async asyncData({params}){
  //   let activedTab = 0;
  //   let detail = await getCourseGroupDetail({id: params.id})
  //   if(+detail.returnCode !== 10001) {
  //     detail.data = {}
  //   }
  //   if(detail.data.introductionImg) {
  //     detail.data.introductionImgList = detail.data.introductionImg.split(",")
  //   } else {
  //     detail.data.introductionImgList = []
  //   }
  //   const tabList = [
  //     {label: '课程介绍', key: 0, state: detail.data.introductionImg !== ''},
  //     {label: '课程安排', key: 1, state: detail.data.hasCurriculum === 1},
  //     {label: '老师介绍', key: 2, state: detail.data.teachers && detail.data.teachers.length > 0},
  //     {label: '课程评价', key: 3, state: false},
  //     {label: '配套网课', key: 4, state: detail.data.relationCourseGroupId && detail.data.relationCourseGroupId.length > 0},
  //   ]

  //   for(let item in tabList) {
  //     if(item.state) {
  //       activedTab = item.key
  //       return 
  //     }
  //   }
  //   // if(detail.data.hasCurriculum === 1) {
  //   //   tabList.push({label: '课程安排', key: 1})
  //   // }
  //   // tabList.push({label: '老师介绍', key: 2},)
  //   // 根据 relationCourseGroupId 判断是否显示配套课程组
  //   // if(detail.data.relationCourseGroupId) {
  //   //   tabList.push({label: '配套网课', key: 4})
  //   // }
  //   return {
  //     detail: detail.data,
  //     activedTab: activedTab,
  //     tabList: tabList,
  //     breadcrumbList: [
  //       // {name: '课程'},
  //       {name: '全部课程', url:'/course'},
  //       {name: detail.data.categoryName, url:''},
  //       {name: detail.data.courseGroupName}
  //     ]
  //   }
  // },
  data() {
    return {
      courseId: null,
      dialogVisible: false,
      dialogSrc: [],
      activedTab: 0,
      detail: null,
      tabList: [],
      breadcrumbList: [
        {name: '全部课程', url:'/course'},
      ]
    }
  },
  mounted() {
    checkOrderStatus(this)
    this.$store.dispatch('clearOrderInfo')
    this.courseId = this.$route.params.id
    if(this.detail) {
      this.breadcrumbList[1].name = this.getClassType(this.detail.categoryName)
      this.breadcrumbList[1].url = `/course?categoryName=${this.getClassType(this.detail.categoryName)}`
    }
    this.getCourseGroupDetail()
    this.getLessonGroupTips()
  },
  components: {
    Breadcrumb,
    CourseGroupDetailCard,
    IntCard,
    Tabs,
    CourseIntroduct,
    CourseList,
    TeacherIntroduct,
    MatchingCourse
  },
  methods: { 
    async getCourseGroupDetail () {
      let response = await getCourseGroupDetail({id: this.courseId})
      if(+response.returnCode !== 10001) {
        return false
      }
      let detail = response.data
      this.detail = detail
      this.tabList = [
        {label: '课程介绍', key: 0, state: true},
        {label: '课程安排', key: 1, state: detail.hasCurriculum === 1},
        {label: '老师介绍', key: 2, state: detail.teachers && detail.teachers.length > 0},
        {label: '课程评价', key: 3, state: false},
        {label: '配套网课', key: 4, state: detail.relationCourseGroupId && detail.relationCourseGroupId.length > 0},
      ]
      this.breadcrumbList = [
        // {name: '课程'},
        {name: '全部课程', url:'/course'},
        // {name: this.getClassType(detail.categoryName), url:`/course?categoryName=${this.getClassType(detail.categoryName)}`},
        {name: detail.categoryRealName, url:`/course?categoryName=` + detail.categoryId },
        {name: detail.courseGroupName}
      ]
    },
    async getLessonGroupTips() {
      let params = {
        courseGroupId: this.courseId
      }
      let response = await getLessonGroupTips(params)
      if(+response.returnCode !== 10001) {
        return false
      }
      
      this.orderId = response.data.orderId
    },
    getClassType(str = "") {
      return str.split(' | ')[2]
    },
    tabsOnChange(val) {
      this.activedTab = val.key
    },
    async toSingup(val) {
      // 关联职位列表，跳转报名步骤 非关联的，就直接跳支付
      let canBuy = await this.checkBeforeCreate()
      if(!canBuy) {
        return false
      }
      if(+val === 1) {
        this.$router.push({path: `/course/signup`})
      } else {
        // this.$router.push({path:`/course/signup`,query:{
        //   step: 2,
        // }})
        this.$router.push({path: `/course/signup?step=2`})
      }
      // window.location.href = `/course/signup`
    },
    async checkBeforeCreate(val) {
      
      let params = {
        courseGroupId: this.detail.id
      }
      let response = await checkBeforeCreate(params)
      if(+response.returnCode !== 10001) {
        this.$message.error(response.returnMsg)
        return false
      } else {
        return true
      }
    },
    showProtocol() {
      getProtocol({id: this.detail.protocolId}).then(res => {
        this.dialogSrc = res.data.protocolImg?.split(',');
        this.dialogVisible = true;
      })
    },
  }
}
</script>

<style lang="less">
.icon{
  display: block;
  width: 22px;
  height: 21px;
}
.int-title{
  margin-left: 7px;
}
.int-teacher{
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  .intt-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .int-btn{
    background-color: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    border: 1px solid #f2f2f2;
    width: 100%;
    &:hover{
      background: #FDEFEF;
      border: 1px solid #FF7773;
      color: #FF3F47;
    }
  }
  p{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
  }
  img{
    display: block;
    width: 77px;
    height: 76px;
    background: #eee;
  }
}
.int-question{
  padding: 28px 0;
  .int-q-item{
    width: 100%;
    height: 20px;
    display: flex;
    cursor: pointer;
    .key{
      color: #FF3F47;
      position: relative;
      min-width: 31px;
      max-width: 35px;
      height: 15px;
      line-height: 15px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 13px;
      &::before{
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 32px;
        height: 10px;
        border-radius: 5px; 
        background: rgba(255, 63, 71, 0.3);
      }
    }
    .text{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover{
      background: #F2F2F2;
    }
  }
}
.course-detail{
  .breadcrumb-box{
    height: 58px;
    display: flex;
    align-items: center;
    // justify-content: center;
  }
  .detail-card{
    margin-bottom: 20px;
  }
  .detail-flex{
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    .class-intro{
      // width: 850px;
      width: 1200px;
      background: #FFFFFF;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      overflow: hidden;
    }
    .class-right{
      width: 320px;
    }
  }
}
</style>