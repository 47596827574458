<template>
  <div class="teacher">
    <div class="t-item" v-for="(item, index) of detail.teachers" :key="index">
      <div class="cover">
        <img :src="item.headPortrait" :alt="item.teacherName">
      </div>
      <div class="t-int">
        <div class="t-name">
          <span>{{item.teacherName}}</span>
          <p class="t-tag">主讲:{{item.content}}</p>
        </div>
        <p class="t-int-content">{{item.introduction}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherIntroduct",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="less" scoped>
.teacher{
  padding: 36px;
  .t-item{
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #e8e8e8;
    // justify-content: space-between;
    .cover{
      width: 100px;
      height: 100px;
      overflow: hidden;
      background:#eee;
      border-radius: 50%;
      margin-right: 18px;
      flex-grow: 0;
      flex-shrink: 0;
      img{
        width: 100%;
      }
    }
    .t-int{
      
      .t-name{
        color: #FF3F47;
        font-size: 18px;
        font-weight: bold;
        margin-right: 22px;
        margin-right: 8px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
      }
      .t-tag{
        height: 19px;
        line-height: 19px;
        background: #F2F2F2;
        border-radius: 6px;
        padding: 0 14px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        margin-left: 8px;
      }
      .t-int-content{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
    }
  }
}
</style>