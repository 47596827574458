<template>
  <div class="box">
    <div class="tabs">
      <template v-for="(item, index) of list" >
        <div
          :key="index" 
          v-if="item.state"
          class="tabs-item" 
          @click="onChange(item)"
          :class="{
            'tabs-item-active': item.key === actived,
            'tabs-item-active-next': item.key === (actived + 1),
            'tabs-item-active-prev': item.key === (actived - 1)
            }">
          <span>{{item.label}}</span>
        </div>
      </template>
      
      
    </div>
    <div class="w_tabs" v-show="!isShow">
      <!-- <div class="tabs-item tabs-item-active">
        <span>课程介绍</span>
      </div> -->
      <div class="tab_box">
        <div class="tabs">
          <template v-for="(item, index) of list" >
            <div
              :key="index" 
              v-if="item.state"
              class="tabs-item" 
              @click="onChange(item)"
              :class="{
                'tabs-item-active': item.key === actived,
                'tabs-item-active-next': item.key === (actived + 1),
                'tabs-item-active-prev': item.key === (actived - 1)
                }">
              <span>{{item.label}}</span>
            </div>
          </template>
        </div>
        
        <div class="buy_box">
          <!-- <span class="price">￥{{detail.currentPrice}}</span>
          <span class="buy_btn">立即购买</span> -->
          <div v-if='btnStateComputed.state === "立即报名"'>
            <span class="price-now">￥{{detail.currentPrice}}</span>
            <span class="price-old" v-if="detail.originalPrice">￥{{detail.originalPrice}}</span>
          </div>
          <template v-if="detail && detail.whetherReation === 2">
            <button class="buy-btn" @click="confirm(btnStateComputed.type)" v-if="btnStateComputed.canClick">{{btnStateComputed.state}}</button>
            <button class="buy-btn buy-btn-gray" v-if="!btnStateComputed.canClick">{{btnStateComputed.state}}</button>
          </template>
          <template v-if="detail && detail.whetherReation !== 2">
            <button class="buy-btn buy-btn-gray" @click="ptclassTip">配套课程</button>
          </template>
          
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { BTNSTATEFILTER } from '@/config/config'
export default {
  name: "Tabs",
  data(){
    return{
      isShow:true
    }
  },
  props:{
    actived: {
      default: 0,
      type:Number
    },
    list: {
      default: () => {
        return []
      },
      type: Array
    },
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted(){
    // 开启滚动监听
    window.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    btnStateComputed() {
      return BTNSTATEFILTER(this.detail)
      
    }
  },
  methods: {
    onChange(val = {}) {
      this.$emit('onChange', val)
    },
    // 滚动监听
    handleScroll () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 550) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
    confirm(type = 0) {
      if(type === 0) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$store.dispatch('setOrderInfo',{
            courseGroupId: this.detail.id,
            courseGroupName: this.detail.courseGroupName,
            jobListId: this.detail.jobListId,
            loCourseInfo: this.detail
          })
          this.$nextTick(() => {
            this.$emit('confirm',this.detail.interviewFlag)
          })
        } else {
          this.$store.dispatch('toggleDialog',{isShow: true, type:0})
        }
      } else {
        this.$router.push({path: `/course/signup?step=3&orderId=${this.orderId}`})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tabs{
  background: linear-gradient(#f2f2f2 50%,#fff 50%);
  font-size: 16px;
  display: flex;
  .tabs-item{
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    cursor: pointer;
    color: #333;
    background: #f2f2f2;
    border-radius: 0 0 10px 10px;
    font-weight: bold;
  }
  .tabs-item-active{
    background: #fff;
    border-radius: 10px;
    color:#FF3F47;
  }
  // .tabs-item-active-prev{
  //   border-radius: 0 0 10px 0;
  // }
  // .tabs-item-active-next{
  //   border-radius: 0 0 0 10px;
  // }
}
.w_tabs{
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: 99;
  background-color: #fff;
  background: linear-gradient(#fff 50%,#fff 50%);
  box-shadow: 0 5px 5px -5px #cccccc;
  .tab_box{
    width:1200px;
    height:72px;
    line-height: 71px;
    margin:0 auto;
    display: flex;
    .tabs{
      width:850px;
      .tabs-item{
        background:#fff;
        border-radius: 0;
      }
    }
    .buy_box{
      width:310px;
      padding:0 20px;
      display: flex;
      justify-content: center;
      .price{
        font-size: 28px;
        color:#FF3F47;
        font-weight: bold;
        margin-left: 90px;
      }
      .buy-btn{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        background: linear-gradient(90deg , #F15F32, #F13232);
        border-radius: 6px;
        text-align: center;
        color: #FFFFFF;
        font-size: 18px;
        margin-right: 15px;
        margin:16px 0;
      }
      .buy-btn-gray{
        background: #dbdbdb;
      }
      .price-now{
        color: #FF3F47;
        font-size: 20px;
        margin-right: 60px;
      }
      .price-old{
        color: #999999;
        font-size: 16px;
        text-decoration:line-through;
      }
    }
  }
}
</style>