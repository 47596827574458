/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-03-29 17:10:21
 * @LastEditTime : 2022-03-29 17:16:20
 * @LastEditors  : BigBigger
 */
import { checkOrder } from '@/api/api';

export function checkOrderStatus (_this) {
  if (_this.$store.state?.userInfo?.token)
    checkOrder().then((res) => {
      const h = _this.$createElement;
      if (+res.returnCode === 10001 && res.data.judgeType)
        _this.$msgbox({
          message: h('span', ['系统检测到您的 ', h('span', { style: 'color: red' }, `（${res.data.courseGroupName || '课程订单'}）`), ' 已通过审核，请尽快前往支付！']),
          title: '提示',
          showCancelButton: true,
          confirmButtonText: '去查看',
          cancelButtonText: '取消',
        }).then(() => {
          _this.$router.push({
            path: `/mine?type=1`,
          });
        });
    });
}