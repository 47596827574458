<!--
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2021-05-10 10:13:58
 * @LastEditors  : XiaoLei
-->
<template>
  <div class="course">
    <div v-for="(groupitem, groupindex) of detail.chapterWebVos" :key="groupindex">
      <div class="course-group" @click="toggleClass(groupindex)" v-if="detail.hasChapter">
        <span class="cg-title">{{groupitem.chapterName}}</span>
        <em class="arrow" :class="{'turnArrow' : listType[groupindex]}"></em>
      </div>
      <template v-if="detail.hasChapter">
        <div v-if="listType[groupindex]">
          <div class="course-item" :class="{'course-can': classitem.liveStatus === 2 || classitem.liveStatus === 3 || classitem.liveStatus === 5}" v-for="(classitem, classindex) of groupitem.courseWebVos" :key="classindex" @click="toPlay(classitem)">
            <div class="ci-left">
              <div class="play-icon" v-if="classitem.liveStatus == 3 || classitem.liveStatus === 5"></div>
              <div class="video-icon" v-else></div>
              <div class="title">{{classitem.courseName}}-{{classitem.teacherName}}</div>
              <div class="time">{{classitem.classTime}}</div>
            </div>
            <div class="ci-right">
              <!-- 
                回放 蓝色
                点播 绿色
                直播中 红色
              -->
              <span :class="{
                'blue': classitem.liveStatus === 5,
                'green': classitem.liveStatus === 3,
                'red': classitem.liveStatus === 2,
                }">{{classitem.liveStatus | liveStatusFilter}}</span>
              
            </div>
          </div>
        </div>
      </template>
      <template v-if="!detail.hasChapter">
        <div>
          <div class="course-item" :class="{'course-can': classitem.liveStatus === 2 || classitem.liveStatus === 3 || classitem.liveStatus === 5}" v-for="(classitem, classindex) of groupitem.courseWebVos" :key="classindex" @click="toPlay(classitem)">
            <div class="ci-left">
              <div class="play-icon" v-if="classitem.liveStatus == 3 || classitem.liveStatus === 5"></div>
              <div class="video-icon" v-else></div>
              <div class="title">{{classitem.courseName}}-{{classitem.teacherName}}</div>
              <div class="time">{{classitem.classTime}}</div>
            </div>
            <div class="ci-right">
              <!-- 
                回放 蓝色
                点播 绿色
                直播中 红色
              -->
              <span :class="{
                'blue': classitem.liveStatus === 5,
                'green': classitem.liveStatus === 3,
                'red': classitem.liveStatus === 2,
                }">{{classitem.liveStatus | liveStatusFilter}}</span>
              
            </div>
          </div>
        </div>
      </template>
    </div>

    
    <!-- <div class="course-item">
      <div class="ci-left">
        <div class="play-icon"></div>
        <div class="title">10月1日上午-蔡楠</div>
        <div class="time">10-01  9:00</div>
      </div>
      <div class="ci-right">
        <span>回放</span>
      </div>
    </div>
    <div class="course-item">
      <div class="ci-left">
        <div class="video-icon"></div>
        <div class="title">10月1日上午-蔡楠</div>
        <div class="time">10-01  9:00</div>
      </div>
      <div class="ci-right">
        <span style="color:#FF3F47;">直播中...</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { LIVE_STATUS } from '@/config/config'
export default {
  name: 'CourseList',
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      listType: [
        true
      ],
    }
  },
  filters: {
    liveStatusFilter(val) {
      return LIVE_STATUS[val] || '未开始'
    }
  },
  mounted() {
  },
  methods:{
    toggleClass(index) {
      // this.listType[index] = !this.listType[index]
      Vue.set(this.listType, index, !this.listType[index])
    },
    toPlay(item) {
      // 如果为购买，提示他先购买
      if(+this.detail.buyFlag !== 1) {
        if(this.$store.state.userInfo && this.$store.state.userInfo.token) {
          this.$message.warning('请先购买该课程')
        }
        else {
          this.$store.dispatch('toggleDialog',{isShow: true, type:0})
        }
        return false
      }
       //  1 暂无直播  2 直播中  3 点播  4   视频生成中   5  回放
      if(item.liveStatus === 2 || item.liveStatus === 3 || item.liveStatus === 5) {
        const id = item.id
        this.$router.push({path: `/course/class/${id}?id=${this.detail.id}`})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.course{
  padding: 20px;
}
.course-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  cursor: pointer;
  padding: 0 20px;
  .cg-title{
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 18px;
    height: 65px;
    line-height: 65px;
    // padding: 0 21px;
  }
  .arrow{
    width: 15px;
    height: 9px;
    display: block;
    background: url("../../assets/images/icon/icon-4.png") no-repeat;
    background-size: contain;
    transition: all 0.3s;
  }
}
.course-item{
  display: flex;
  padding: 0 27px;
  height: 59px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  cursor: not-allowed;
  transition: all 0.3s;
  &:hover{
    background: #F2F2F2;
  }
  .ci-left{
    display: flex;
    align-items: center;
  }
  .title{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    margin-left: 12px;
  }
  .time{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-left: 12px;
  }
}
.course-can{
  cursor: pointer;
}
.ci-right{
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
}
.play-icon{
  width: 21px;
  height: 21px;
  background: url('../../assets/images/icon/icon-3.png') no-repeat;
  background-size: contain;
}
.video-icon{
  width: 26px;
  height: 18px;
  background: url('../../assets/images/icon/icon-6.png') no-repeat;
  background-size: contain;
}
.turnArrow{
  transform: rotate(180deg);
}
.blue{
  color: #2E8DF4;
}
.green{
  color: #00B262;
}
.red{
  color: #FF3F47;
}
</style>
